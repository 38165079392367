import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import OutlinedSection from '../outlined-section';
import { getStep8Fields } from './helper';
import useStyles from '../styles';
import TextFieldInputV2 from 'components/text-field';
import ProtectionFields from './ProtectionFields';
import { getStructuredDataForFields } from 'utils/structuredDataPreviewPreparation';
import { ComponentsStructuredDataPreview } from '../structured-data-preview';
import DataTable from '../../create-component-structured-data/components/data-table';
import React from 'react';
import { StepFieldsProps } from '../utils';

const SectionFields = ({
  componentStructuredData,
  components,
  doNotIncludeStructuredDataFromComponents,
  languageCode,
}: {
  componentStructuredData: any;
  components: any;
  doNotIncludeStructuredDataFromComponents: any;
  languageCode: any;
}) => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const sections = getStep8Fields();
  const { t } = useTranslation(['fields', 'sds_authoring']);
  const classes = useStyles();
  const structuredDataByComponent: any = getStructuredDataForFields(
    componentStructuredData,
    components.filter(
      (el: any) => !doNotIncludeStructuredDataFromComponents.includes(el.id)
    ),
    ['exposure_limits', 'exposure_controls']
  );

  return (
    <>
      {sections.map((section: StepFieldsProps, index: number) => {
        if (
          section.title ===
          translations.headers['8_1_header_control_parameters']
        ) {
          return (
            <OutlinedSection key={index} title={section.title}>
              <Grid item xs={12}>
                {structuredDataByComponent.exposure_limits?.length > 0 && (
                  <ComponentsStructuredDataPreview
                    fieldsNames={[
                      'type',
                      'description',
                      'duration',
                      'limit_value',
                      'source',
                      'comment',
                    ]}
                    fieldsStyles={{
                      type: { width: '1;5%' },
                      description: { width: '25%' },
                      duration: { width: '10%' },
                      limit_value: { width: '10%' },
                      source: { width: '15%' },
                      comment: { width: '25%' },
                    }}
                    structuredDataPerComponent={
                      structuredDataByComponent.exposure_limits
                    }
                    translations={translations}
                  />
                )}
              </Grid>
              <Grid key={index} item xs={12}>
                <Field
                  name="exposure_controls"
                  component={TextFieldInputV2}
                  classes={{ root: classes.textInput }}
                  multiline={true}
                />
              </Grid>
              <Grid key={index} item xs={12}>
                <FieldArray
                  name="exposure_controls_structured_data"
                  formName={'exposure_controls_structured_data'}
                  component={DataTable}
                  translations={translations}
                  fieldsNames={[
                    'type',
                    'exposure',
                    'value',
                    'population',
                    'effects',
                    'comment',
                    'action',
                  ]}
                  fieldsStyles={{
                    type: { width: '10%' },
                    exposure: { width: '15%' },
                    value: { width: '10%' },
                    population: { width: '25%' },
                    effects: { width: '20%' },
                    comment: { width: '20%' },
                  }}
                  fieldsProps={{
                    type: {
                      type: 'select',
                      options: [
                        {
                          value: 'dnel',
                          label: t('create_component_structured_data:dnel'),
                        },
                        {
                          value: 'pnec',
                          label: t('create_component_structured_data:pnec'),
                        },
                      ],
                    },
                    exposure: {
                      type: 'select',
                      options: [
                        {
                          value: 'human_dermal',
                          label: t(
                            'create_component_structured_data:human_dermal'
                          ),
                        },
                        {
                          value: 'human_inhalation',
                          label: t(
                            'create_component_structured_data:human_inhalation'
                          ),
                        },
                        {
                          value: 'human_oral',
                          label: t(
                            'create_component_structured_data:human_oral'
                          ),
                        },
                        {
                          value: 'environment_freshwater',
                          label: t(
                            'create_component_structured_data:environment_freshwater'
                          ),
                        },
                        {
                          value: 'environment_marine',
                          label: t(
                            'create_component_structured_data:environment_marine'
                          ),
                        },
                        {
                          value: 'environment_water_sporadic_release',
                          label: t(
                            'create_component_structured_data:environment_water_sporadic_release'
                          ),
                        },
                        {
                          value: 'environment_sediment_marine',
                          label: t(
                            'create_component_structured_data:environment_sediment_marine'
                          ),
                        },
                        {
                          value: 'environment_sewage',
                          label: t(
                            'create_component_structured_data:environment_sewage'
                          ),
                        },
                        {
                          value: 'environment_sediment_freshwater',
                          label: t(
                            'create_component_structured_data:environment_sediment_freshwater'
                          ),
                        },
                        {
                          value: 'environment_soil',
                          label: t(
                            'create_component_structured_data:environment_soil'
                          ),
                        },
                      ],
                    },
                    population: {
                      type: 'select',
                      options: [
                        {
                          value: 'employees',
                          label: translations.layout?.employees,
                        },
                        {
                          value: 'environment',
                          label: translations.layout?.environment,
                        },
                        {
                          value: 'freshwater',
                          label: translations.layout?.freshwater,
                        },
                        {
                          value: 'marine_water',
                          label: translations.layout?.marine_water,
                        },
                        {
                          value: 'sewage_treatment_plant',
                          label: translations.layout?.sewage_treatment_plant,
                        },
                        {
                          value: 'freshwater_sediment',
                          label: translations.layout?.freshwater_sediment,
                        },
                        {
                          value: 'marine_sediment',
                          label: translations.layout?.marine_sediment,
                        },
                        {
                          value: 'freshwater',
                          label: translations.layout?.soil,
                        },
                      ],
                    },
                  }}
                />
              </Grid>
              {structuredDataByComponent.exposure_controls?.length > 0 && (
                <ComponentsStructuredDataPreview
                  fieldsNames={[
                    'type',
                    'exposure',
                    'value',
                    'population',
                    'effects',
                    'comment',
                  ]}
                  fieldsStyles={{
                    type: { width: '10%' },
                    exposure: { width: '15%' },
                    value: { width: '10%' },
                    population: { width: '25%' },
                    effects: { width: '20%' },
                    comment: { width: '20%' },
                  }}
                  structuredDataPerComponent={
                    structuredDataByComponent.exposure_controls
                  }
                  translations={translations}
                />
              )}
              {section.fields.map((field: any, index: any) => {
                if (field.isStructuredDataField) {
                  return (
                    <Grid key={index} item xs={12}>
                      {field.component}
                    </Grid>
                  );
                }
                return (
                  <Grid key={index} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        }

        if (
          section.title === translations.headers['8_2_header_exposure_controls']
        ) {
          return (
            <OutlinedSection key={index} title={section.title}>
              <Grid item xs={12}>
                <Field
                  name="personal_protection"
                  component={TextFieldInputV2}
                  label={translations.sections.personal_protection}
                  classes={{ root: classes.textInput }}
                  multiline={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translations.sections.respiratory_protection}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="respiratory_protection_description"
                  component={TextFieldInputV2}
                  classes={{ root: classes.textInput }}
                  multiline={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  categories={['respirators']}
                  name="respiratoryProtection"
                  component={ProtectionFields}
                  languageCode={languageCode}
                  popupTitle={translations.sections.respiratory_protection}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translations.sections.eyes_protection
                    ? translations.sections.eyes_protection
                    : t('fields:eyes_protection')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="eyes_protection_description"
                  component={TextFieldInputV2}
                  classes={{ root: classes.textInput }}
                  multiline={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  categories={['eye_protection', 'face_protection']}
                  name="eyesProtection"
                  component={ProtectionFields}
                  languageCode={languageCode}
                  popupTitle={
                    translations.sections.eyes_protection
                    ? translations.sections.eyes_protection
                    : t('fields:eyes_protection')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translations.sections.hands_protection
                    ? translations.sections.hands_protection
                    : t('fields:hands_protection')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="hands_protection_description"
                  component={TextFieldInputV2}
                  classes={{ root: classes.textInput }}
                  multiline={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  categories={['gloves']}
                  name="handsProtection"
                  component={ProtectionFields}
                  languageCode={languageCode}
                  popupTitle={
                    translations.sections.hands_protection
                    ? translations.sections.hands_protection
                    : t('fields:hands_protection')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {translations.sections.body_protection
                    ? translations.sections.body_protection
                    : t('fields:body_protection')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="body_protection_description"
                  component={TextFieldInputV2}
                  classes={{ root: classes.textInput }}
                  multiline={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  categories={['body_protection']}
                  name="bodyProtection"
                  component={ProtectionFields}
                  languageCode={languageCode}
                  popupTitle={
                    translations.sections.body_protection
                    ? translations.sections.body_protection
                    : t('fields:body_protection')
                  }
                />
              </Grid>
              {section.fields.map((field: any, index: any) => {
                return (
                  <Grid key={index} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        }
      })}
    </>
  );
};

export default SectionFields;
