import SelectInput from 'components/select-input';
import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { required } from '../validators';
import { useMemo } from 'react';
// @ts-ignore
import countryList from 'react-select-country-list';
import { StepFieldsProps } from '../utils';

export const getStep1Fields = (): Array<StepFieldsProps> => {
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((el: any) => {
          return { value: el.value.toLowerCase(), label: el.label };
        }),
    []
  );

  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers.header_1_1,
      fields: [
        {
          name: 'product_name',
          component: TextFieldInputV2,
          label: translations.sections.product_name,
          xs: 12,
        },
        {
          name: 'description',
          component: TextFieldInputV2,
          label: translations.sections.description,
          xs: 4,
        },
        {
          name: 'product_number',
          component: TextFieldInputV2,
          label: translations.sections.product_number,
          xs: 4,
        },
        {
          name: 'ufi',
          component: TextFieldInputV2,
          label: translations.sections.ufi,
          xs: 4,
        },
        {
          name: 'trade_name',
          component: TextFieldInputV2,
          label: translations.sections.trade_name,
          xs: 8,
        },
        {
          name: 'synonyms',
          component: TextFieldInputV2,
          label: translations.sections.synonyms,
          multiline: true,
          xs: 4,
        },
      ],
    },
    {
      title: translations.headers.header_1_2,
      fields: [
        {
          name: 'type_of_product',
          component: TextFieldInputV2,
          label: translations.sections.type_of_product
            ? translations.sections.type_of_product
            : t('fields:type_of_product'),
          xs: 4,
        },
        {
          name: 'relevant_use',
          component: TextFieldInputV2,
          label: translations.sections.relevant_use
            ? translations.sections.relevant_use
            : t('sds_authoring:relevant_use'),
          multiline: true,
          xs: 4,
        },
        {
          name: 'uses_advised_against',
          component: TextFieldInputV2,
          label: translations.sections.uses_advised_against,
          multiline: true,
          xs: 4,
        },
      ],
    },
    {
      title: translations.headers.header_1_3,
      fields: [
        {
          name: 'manufacturer_company_name',
          component: TextFieldInputV2,
          label: translations.sections.manufacturer_company_name,
          xs: 8,
        },
        {
          name: 'manufacturer_street',
          component: TextFieldInputV2,
          label: translations.sections.street
            ? translations.sections.street
            : t('fields:street'),
          xs: 4,
        },
        {
          name: 'manufacturer_postcode',
          component: TextFieldInputV2,
          label: translations.sections.postcode
            ? translations.sections.postcode
            : t('fields:postcode'),
          xs: 8,
        },
        {
          name: 'manufacturer_city',
          component: TextFieldInputV2,
          label: translations.sections.city
            ? translations.sections.city
            : t('fields:manufacturer_city'),
          xs: 4,
        },
        {
          name: 'manufacturer_state',
          component: TextFieldInputV2,
          label: translations.sections.state
            ? translations.sections.state
            : t('fields:state'),
          xs: 4,
        },
        {
          name: 'manufacturer_company_country',
          component: SelectInput,
          label: translations.sections.country,
          options: countryOptions,
          required: true,
          valueField: 'label',
          labelField: 'label',
          xs: 4,
        },
        {
          name: 'manufacturer_telephone',
          component: TextFieldInputV2,
          label: translations.sections.telephone
            ? translations.sections.telephone
            : t('fields:telephone'),
          xs: 4,
        },
      ],
    },
    {
      title: translations.headers.header_1_4,
      fields: [
        {
          validate: [required],
          name: 'emergency_provider',
          component: TextFieldInputV2,
          label: translations.sections.emergency_provider,
          xs: 12,
        },
        {
          validate: [required],
          name: 'emergency_telephone',
          component: TextFieldInputV2,
          label: translations.sections.emergency_telephone,
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
