import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
//@ts-ignore
import countryList from 'react-select-country-list';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { Checkbox, Typography } from '@mui/material';
import DataTable from '../data-table';
import InputField from '../input-field';
import useBaseStyles from '../styles';
import OutlinedSection from '../outlined-section';

interface ComponentExposureProtectionsFormDataProps {
  htmlSection?: any;
}

const ComponentExposureProtectionsFormData = (
  props: ComponentExposureProtectionsFormDataProps &
    InjectedFormProps<{}, ComponentExposureProtectionsFormDataProps>
) => {
  const classes = useBaseStyles();
  const { htmlSection } = props;
  const { t } = useTranslation(['create_component_structured_data']);
  const [showSectionData, setShowSectionData] = useState(false);

  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((el: any) => {
          return { value: el.label, label: el.label };
        }),
    []
  );

  return (
    <form className={classes.form}>
      <OutlinedSection
        title={translations.headers['8_header_exposure_controls']}
        variant="h5"
      >
        <Typography variant="h6">
          {translations.headers['8_1_header_control_parameters']}
        </Typography>
        <div style={{}}>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              color={'primary'}
              onClick={() => setShowSectionData(!showSectionData)}
              checked={showSectionData}
            />
            <Typography>{t('sds_authoring:show_imported_sds_data')}</Typography>
          </div>
          {showSectionData && htmlSection && (
            <div
              className={classes.reviewDataRightSideContent}
              dangerouslySetInnerHTML={{
                __html: htmlSection,
              }}
            />
          )}
        </div>
        <Field
          name="exposure_controls_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {translations.sections.exposure_limits}
        </Typography>
        <FieldArray
          name="exposure_limits"
          formName={'exposure_limits'}
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'type',
            'description',
            'duration',
            'limit_value',
            'source',
            'comment',
            'action',
          ]}
          fieldsStyles={{
            type: { width: '15%' },
            description: { width: '25%' },
            duration: { width: '10%' },
            limit_value: { width: '10%' },
            source: { width: '15%' },
            comment: { width: '25%' },
          }}
          fieldsProps={{
            type: {
              type: 'select',
              options: [
                { value: 'STEL', label: 'STEL' },
                { value: 'TLV-STEL', label: 'TLV-STEL' },
                { value: 'IOELV STEL', label: 'IOELV STEL' },
                { value: 'IOELV-TWA', label: 'IOELV-TWA' },
                { value: 'EU-IOELV', label: 'EU-IOELV' },
                { value: 'TWA', label: 'TWA' },
                { value: 'TLV-TWA', label: 'TLV-TWA' },
                { value: 'WEL-TWA', label: 'WEL-TWA' },
                { value: 'WEL-STEL', label: 'WEL-STEL' },
                { value: 'WEL - SHORT TERM', label: 'WEL - SHORT TERM' },
                { value: 'WEL - LONG TERM', label: 'WEL - LONG TERM' },
                { value: 'OSHA PEL', label: 'OSHA PEL' },
                { value: 'GV', label: 'GV' },
              ],
            },
            source: {
              type: 'select',
              options: countryOptions,
            },
          }}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {translations.sections.dnel_pnec}
        </Typography>
        <FieldArray
          name="exposure_controls"
          formName={'exposure_controls'}
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'type',
            'exposure',
            'value',
            'population',
            'effects',
            'comment',
            'action',
          ]}
          fieldsStyles={{
            type: { width: '10%' },
            exposure: { width: '15%' },
            value: { width: '10%' },
            population: { width: '25%' },
            effects: { width: '20%' },
            comment: { width: '20%' },
          }}
          fieldsProps={{
            type: {
              type: 'select',
              options: [
                {
                  value: 'dnel',
                  label: t('create_component_structured_data:dnel'),
                },
                {
                  value: 'pnec',
                  label: t('create_component_structured_data:pnec'),
                },
              ],
            },
            exposure: {
              type: 'select',
              options: [
                {
                  value: 'human_dermal',
                  label: t('create_component_structured_data:human_dermal'),
                },
                {
                  value: 'human_inhalation',
                  label: t('create_component_structured_data:human_inhalation'),
                },
                {
                  value: 'human_oral',
                  label: t('create_component_structured_data:human_oral'),
                },
                {
                  value: 'environment_freshwater',
                  label: t(
                    'create_component_structured_data:environment_freshwater'
                  ),
                },
                {
                  value: 'environment_marine',
                  label: t(
                    'create_component_structured_data:environment_marine'
                  ),
                },
                {
                  value: 'environment_soil',
                  label: t('create_component_structured_data:environment_soil'),
                },
              ],
            },
            population: {
              type: 'select',
              options: [
                {
                  value: 'employees',
                  label: translations.layout?.employees,
                },
                {
                  value: 'environment',
                  label: translations.layout?.environment,
                },
                {
                  value: 'freshwater',
                  label: translations.layout?.freshwater,
                },
                {
                  value: 'marine_water',
                  label: translations.layout?.marine_water,
                },
                {
                  value: 'sewage_treatment_plant',
                  label: translations.layout?.sewage_treatment_plant,
                },
                {
                  value: 'freshwater_sediment',
                  label: translations.layout?.freshwater_sediment,
                },
                {
                  value: 'marine_sediment',
                  label: translations.layout?.marine_sediment,
                },
                {
                  value: 'freshwater',
                  label: translations.layout?.soil,
                },
              ],
            },
          }}
        />
      </OutlinedSection>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  const componentId = state.pageReducer.selectedComponentId;
  const structuredData = state.pageReducer.structuredComponentData[componentId];

  return {
    htmlSection: state.pageReducer.componentDetails?.html_sections[8],
    initialValues: {
      exposure_controls_desc: structuredData?.exposure_controls_desc,
      exposure_limits: structuredData?.exposure_limits,
      exposure_controls: structuredData?.exposure_controls,
      acute_toxicity: structuredData?.acute_toxicity,
      skin_corrosion: structuredData?.skin_corrosion,
      sensitization: structuredData?.sensitization,
      mutagenicity: structuredData?.mutagenicity,
      carcinogenicity: structuredData?.carcinogenicity,
      reproductive_toxicity: structuredData?.reproductive_toxicity,
      teratogenicity: structuredData?.teratogenicity,
      toxicity: structuredData?.toxicity,
      acute_toxicity_desc: structuredData?.acute_toxicity_desc,
      skin_corrosion_desc: structuredData?.skin_corrosion_desc,
      sensitization_desc: structuredData?.sensitization_desc,
      mutagenicity_desc: structuredData?.mutagenicity_desc,
      carcinogenicity_desc: structuredData?.carcinogenicity_desc,
      reproductive_toxicity_desc: structuredData?.reproductive_toxicity_desc,
      teratogenicity_desc: structuredData?.teratogenicity_desc,
      specific_target_organ_toxicity_repeated:
        structuredData?.specific_target_organ_toxicity_repeated,
      specific_target_organ_toxicity_single:
        structuredData?.specific_target_organ_toxicity_single,
      eye_damage: structuredData?.eye_damage,
      toxicity_desc: structuredData?.toxicity_desc,
      biodegradability: structuredData?.biodegradability,
      biodegradability_desc: structuredData?.biodegradability_desc,
      bioaccumulative_potential: structuredData?.bioaccumulative_potential,
      bioaccumulative_potential_desc:
        structuredData?.bioaccumulative_potential_desc,
      eye_damage_desc: structuredData?.eye_damage_desc,
      specific_target_organ_toxicity_repeated_desc:
        structuredData?.specific_target_organ_toxicity_repeated_desc,
      specific_target_organ_toxicity_single_desc:
        structuredData?.specific_target_organ_toxicity_single_desc,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'componentStructuredData',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ComponentExposureProtectionsFormData)
);
