import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  IconButton,
  FormControl,
  Paper,
  SelectChangeEvent,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DesktopDialog from 'components/dialog/Dialog';
import SearchBar from 'components/search-bar/SearchBar';
import { importSDS, getAllPdfs } from 'api';
import { Loader } from 'components';
import { renderSnackbar } from 'utils';

import { availableLanguages } from '../../constants';
import useStyles from './importSDSPopupStyles';

const SelectSDSToImportPopup = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring', 'popups', 'actions']);
  const history = useHistory();
  const languageCode = useSelector(
    (state: any) => state.pageReducer.languageCode
  );
  const [loading, setLoading] = React.useState(false);
  const [sdsPaginatedData, setSdsPaginatedData] = React.useState({
    results: [],
    count: 100,
  });
  const [selectedLanguage, setSelectedLanguage] = React.useState(languageCode);
  const [loadingId, setLoadingId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorText, setErrorText] = React.useState<string | null>(null);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [keyword, setKeyword] = React.useState('');

  const handleLanguageChange = (
    event: SelectChangeEvent<any>,
    child: React.ReactNode
  ) => {
    setSelectedLanguage(event.target.value);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.currentTarget.value);
    setPage(0);
  };

  const handleAddSubmitSubstance = (value: any) => {
    setLoadingId(value);
    const addSDSToLocationRequest = importSDS(value);
    addSDSToLocationRequest.then((response) => {
      if (response?.status === 201) {
        renderSnackbar([t('popups:successfully_import')]);
        setLoadingId(null);
        onClose();
        history.push(`/sds-authoring/${response.data.id}`);
      } else {
        renderSnackbar([t('popups:import_failed')]);
      }
    });
  };

  const submitSearch = (page: number, searchValue: string) => {
    setLoadingId(null);
    if (!searchValue || searchValue.length < 3) {
      setSdsPaginatedData({
        results: [],
        count: 100,
      });
      setErrorText(t('sds_authoring:search_min_length'));
      return;
    }
    setLoading(true);
    const getAllPdfsRequest = getAllPdfs({
      search_product: searchValue ? searchValue : '',
      page: page + 1,
      page_size: rowsPerPage,
      language_code: selectedLanguage !== 'all' ? selectedLanguage : '',
      order_by: 'sds_pdf_revision_date',
      // search_by_id: true,
    });
    getAllPdfsRequest.then((response) => {
      if (response.status === 200) {
        setSdsPaginatedData(response.data);
        if (response.data.results.length === 0) {
          setErrorText('');
        }
        setLoading(false);
      }
    });
  };

  const displayError = () => {
    return <div style={{ marginTop: '20px' }}>{errorText}</div>;
  };

  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }
    submitSearch(page, keyword);
  }, [page, rowsPerPage]);

  if (!open) {
    return null;
  }

  return (
    <DesktopDialog
      onClose={() => {
        onClose();
      }}
      maxWidth={'md'}
      classes={{ root: classes.dialog }}
      title={t('popups:select_substance_to_import')}
      fullWidth={true}
    >
      <div className={classes.searchInputsWrapper}>
        <SearchBar
          id="import-substance-search-input"
          placeHolder={t('popups:filter_by')}
          onSearch={(text: string) => {
            setKeyword(text);
            submitSearch(0, text);
          }}
        />
        <FormControl variant="standard">
          <InputLabel>{t('sds_authoring:language')}</InputLabel>
          <Select
            id="language"
            labelId="language-input-label"
            value={selectedLanguage}
            label={t('sds_authoring:language')}
            className={classes.selectInput}
            onChange={handleLanguageChange}
            MenuProps={{ classes: { paper: classes.selectMenu } }}
          >
            <MenuItem key={99999} value={'all'}>
              {t('sds_authoring:all')}
            </MenuItem>
            {availableLanguages.map((el, i) => {
              return (
                <MenuItem key={i} value={el.value}>
                  {el.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div className={classes.content}>
        {loading ? (
          <Loader />
        ) : sdsPaginatedData.results.length < 1 ? (
          displayError()
        ) : (
          <Paper elevation={3} sx={{ mt: 2 }}>
            <Table size="small" aria-label="a dense table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width={'5%'} />
                  <TableCell width={'25%'}>{t('sds_authoring:name')}</TableCell>
                  <TableCell width={'25%'}>
                    {t('sds_authoring:supplier_name')}
                  </TableCell>
                  <TableCell width={'25%'}>
                    {t('sds_authoring:revision_date')}
                  </TableCell>
                  <TableCell>{t('sds_authoring:regulation_area')}</TableCell>
                  <TableCell width={'20%'}>
                    {t('sds_authoring:language')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sdsPaginatedData.results.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell width={'5%'} align={'right'}>
                      {loadingId && loadingId === row.id ? (
                        <IconButton style={{ color: 'rgb(98, 109, 249)' }}>
                          <CircularProgress
                            style={{ width: '20px', height: '20px' }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => handleAddSubmitSubstance(row.id)}
                          color='primary'
                        >
                          <AddCircleOutlineIcon style={{ fontSize: 22 }} />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell width={'25%'} component="th" scope="row">
                      {row.sds_pdf_product_name}
                    </TableCell>
                    <TableCell width={'25%'}>
                      {row.sds_pdf_manufacture_name}
                    </TableCell>
                    <TableCell width={'25%'}>
                      {row.sds_pdf_revision_date}
                    </TableCell>
                    <TableCell>{row.regulation_area}</TableCell>
                    <TableCell width={'20%'}>{row.language}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={sdsPaginatedData.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('sds_authoring:rows_per_page')}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        )}
      </div>
    </DesktopDialog>
  );
};

export default SelectSDSToImportPopup;
