import { connect, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Typography } from '@mui/material';
import useStyles from '../styles';
import SectionFields from './SectionFields';
import SubmitButton from '../submit-btn';
import { CustomFormProps } from '../utils';
import SaveAsButton from 'components/save-as-btn';

interface ExposureProtectionFormProps extends CustomFormProps {
  overwrittenDescriptions: any;
  languageCode: any;
  components: any;
  doNotIncludeStructuredDataFromComponents: any;
  componentStructuredData: any;
}

const ExposureProtectionForm = (
  props: ExposureProtectionFormProps &
    InjectedFormProps<{}, ExposureProtectionFormProps>
) => {
  const classes = useStyles();
  const {
    handleNext,
    dirty,
    overwrittenDescriptions,
    languageCode,
    components,
    doNotIncludeStructuredDataFromComponents,
    componentStructuredData,
    handleCopy,
  } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.exposureProtectionForm);
  const sdsInfo = useSelector((state: any) => state.pageReducer.sdsInfo);

  const handleSubmit = () => {
    if (dirty) {
      const respiratoryProtectionIds =
        form?.values?.respiratoryProtection?.length > 0
          ? form?.values?.respiratoryProtection?.map((el: any) => el.id)
          : sdsInfo?.respiratory_protection?.map((el: any) => el.id);
      const eyesProtectionIds =
        form?.values?.eyesProtection?.length > 0
          ? form?.values?.eyesProtection?.map((el: any) => el.id)
          : sdsInfo?.eyes_protection?.map((el: any) => el.id);
      const handsProtectionIds =
        form?.values?.handsProtection?.length > 0
          ? form?.values?.handsProtection?.map((el: any) => el.id)
          : sdsInfo?.hands_protection?.map((el: any) => el.id);
      const bodyProtectionIds =
        form?.values?.bodyProtection?.length > 0
          ? form?.values?.bodyProtection?.map((el: any) => el.id)
          : sdsInfo?.body_protection?.map((el: any) => el.id);

      handleNext({
        ...form.values,
        body_protection_input: bodyProtectionIds,
        hands_protection_input: handsProtectionIds,
        eyes_protection_input: eyesProtectionIds,
        respiratory_protection_input: respiratoryProtectionIds,
        protections_overwritten_descriptions: overwrittenDescriptions,
      });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['8_header_exposure_controls']}
      </Typography>
      <form onSubmit={handleSubmit}>
        <SectionFields
          components={components}
          doNotIncludeStructuredDataFromComponents={
            doNotIncludeStructuredDataFromComponents
          }
          componentStructuredData={componentStructuredData}
          languageCode={languageCode}
        />
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.exposureProtectionForm?.values) {
    return {
      initialValues: {
        exposure_limits: state.pageReducer.sdsInfo?.exposure_limits,
        exposure_limits_structured_data:
          state.pageReducer.sdsInfo?.exposure_limits_structured_data,
        exposure_controls_structured_data:
          state.pageReducer.sdsInfo?.exposure_controls_structured_data,
        monitoring_method: state.pageReducer.sdsInfo?.monitoring_method,
        occupational_limits: state.pageReducer.sdsInfo?.occupational_limits,
        biological_limits: state.pageReducer.sdsInfo?.biological_limits,
        dnel: state.pageReducer.sdsInfo?.dnel,
        required_monitoring: state.pageReducer.sdsInfo?.required_monitoring,
        large_scale: state.pageReducer.sdsInfo?.large_scale,
        small_scale: state.pageReducer.sdsInfo?.small_scale,
        protective_clothing: state.pageReducer.sdsInfo?.protective_clothing,
        respiratory_protection_description:
          state.pageReducer.sdsInfo?.respiratory_protection_description,
        eyes_protection_description:
          state.pageReducer.sdsInfo?.eyes_protection_description,
        hands_protection_description:
          state.pageReducer.sdsInfo?.hands_protection_description,
        body_protection_description:
          state.pageReducer.sdsInfo?.body_protection_description,
        recommended_filter_type:
          state.pageReducer.sdsInfo?.recommended_filter_type,
        stel: state.pageReducer.sdsInfo?.stel,
        other_exposure_info: state.pageReducer.sdsInfo?.other_exposure_info,
        long_term_exposure_limit:
          state.pageReducer.sdsInfo?.long_term_exposure_limit,
        exposure_time: state.pageReducer.sdsInfo?.exposure_time,
        route_of_exposure: state.pageReducer.sdsInfo?.route_of_exposure,
        pnec: state.pageReducer.sdsInfo?.pnec,
        personal_protection: state.pageReducer.sdsInfo?.personal_protection,
        skin_protection_description:
          state.pageReducer.sdsInfo?.skin_protection_description,
        occupational_exposure_control:
          state.pageReducer.sdsInfo?.occupational_exposure_control,
        material_of_gloves: state.pageReducer.sdsInfo?.material_of_gloves,
        penetration_time_of_gloves:
          state.pageReducer.sdsInfo?.penetration_time_of_gloves,
        protective_clothing_material:
          state.pageReducer.sdsInfo?.protective_clothing_material,
        engineering_controls: state.pageReducer.sdsInfo?.engineering_controls,
        personal_protection_symbols:
          state.pageReducer.sdsInfo?.personal_protection_symbols,
        other_protective_equipment:
          state.pageReducer.sdsInfo?.other_protective_equipment,
        exposure_protective_measures:
          state.pageReducer.sdsInfo?.exposure_protective_measures,
        environmental_exposure_controls:
          state.pageReducer.sdsInfo?.environmental_exposure_controls,
        exposure_controls: state.pageReducer.sdsInfo?.exposure_controls,
        respiratoryProtection: state.pageReducer.sdsInfo?.respiratory_protection
          ? state.pageReducer.sdsInfo.respiratory_protection
          : [],
        eyesProtection: state.pageReducer.sdsInfo?.eyes_protection
          ? state.pageReducer.sdsInfo.eyes_protection
          : [],
        handsProtection: state.pageReducer.sdsInfo?.hands_protection
          ? state.pageReducer.sdsInfo.hands_protection
          : [],
        bodyProtection: state.pageReducer.sdsInfo?.body_protection
          ? state.pageReducer.sdsInfo.body_protection
          : [],
        emergencyActions: state.pageReducer.sdsInfo?.emergency_actions
          ? state.pageReducer.sdsInfo.emergency_actions
          : [],
      },
      sdsId: state.pageReducer.pdfId,
      components: state.form.ingredientsForm?.values?.ingredients
        ? state.form.ingredientsForm?.values?.ingredients
        : state.pageReducer?.sdsInfo?.components
        ? state.pageReducer?.sdsInfo?.components
        : [],
      doNotIncludeStructuredDataFromComponents:
        state.pageReducer.doNotIncludeStructuredDataFromComponents,
      componentStructuredData: state.pageReducer.structuredComponentData,
      overwrittenDescriptions: state.pageReducer.overwrittenPPEDescriptions,
      formEdited: state.pageReducer.formEdited,
      languageCode: state.form.section0Form?.values?.language,
    };
  }
  return {
    sdsId: state.pageReducer.pdfId,
    components: state.form.ingredientsForm?.values?.ingredients
      ? state.form.ingredientsForm?.values?.ingredients
      : state.pageReducer?.sdsInfo?.components
      ? state.pageReducer?.sdsInfo?.components
      : [],
    doNotIncludeStructuredDataFromComponents:
      state.pageReducer.doNotIncludeStructuredDataFromComponents,
    componentStructuredData: state.pageReducer.structuredComponentData,
    overwrittenDescriptions: state.pageReducer.overwrittenPPEDescriptions,
    formEdited: state.pageReducer.formEdited,
    languageCode: state.form.section0Form?.values?.language,
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, ExposureProtectionFormProps>({
    form: 'exposureProtectionForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ExposureProtectionForm)
);
