import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Paper,
  Tooltip,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { get, post } from 'api';
import {
  ConfirmActionPopup,
  SelectComponentFromOurComponentsList,
  SelectComponentsPopup,
} from 'components';
import CreateComponentStructuredDataPopup from 'components/create-component-structured-data';
import TextFieldInputV2 from 'components/text-field';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setComponentStructuredData,
  setSelectedComponentId,
  setDoNotIncludeStructuredDataFromComponents,
  setStructuredDataEdited,
} from 'reducers/pageAction';
import { change, Field } from 'redux-form';
//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { renderSnackbar } from '../../../utils';
import IngredientsTable from './ingredients-table';
import useStyles from './styles';

let timer: any;

const Ingredients = (props: any) => {
  const { fields, languageCode, isSubstanceSDS } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'actions',
    'sds_authoring',
    'tooltips',
    'popups',
  ]);
  const classes = useStyles();
  const selectedComponentId = useSelector(
    (state: any) => state.pageReducer.selectedComponentId
  );

  const [openAddComponentPopup, setOpenAddComponentPopup] =
    React.useState(false);
  const [openAddOurComponentPopup, setOpenAddOurComponentPopup] =
    React.useState(false);
  const [openCreateStructuredDataPopup, setOpenCreateStructuredDataPopup] =
    React.useState(false);
  const [componentToAddInOurList, setComponentToAddInOurList] =
    React.useState<any>(null);
  const [openConfirmAddComponentPopup, setOpenConfirmAddComponentPopup] =
    React.useState(false);
  const [searchResults, setSearchResults] = React.useState([]);
  const structuredData = useSelector(
    (state: any) => state.pageReducer.structuredComponentData
  );
  const doNotIncludeStructuredDataFromComponents = useSelector(
    (state: any) => state.pageReducer.doNotIncludeStructuredDataFromComponents
  );
  const handleOpenAddStructuredComponentData = (componentId: any) => {
    dispatch(setSelectedComponentId(componentId));
    setOpenCreateStructuredDataPopup(true);
  };

  const handleChange = (e: any, fieldName: any) => {
    const searchValue = e?.target.value;
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      const getComponentsRequest = get('/casRegister', {
        [fieldName]: searchValue,
      });
      getComponentsRequest.then((response) => {
        if (response?.status === 200) {
          setSearchResults(response.data);
        }
      });
    }, 1500);
  };

  const handleAddComponentToOurList = (
    sdsId: any,
    cas_no: any,
    forceAddSameCasNo: any,
    componentIndex: any
  ) => {
    const addComponent = post('/sdsAuthoring/customerComponents/', {
      component_sds: sdsId,
      cas_no: cas_no,
      force_add: forceAddSameCasNo,
    });
    addComponent.then((response) => {
      if (response?.status === 201) {
        setComponentToAddInOurList(null);
      } else if (
        response.data?.error_code ===
        'COMPONENT_WITH_SAME_CAS_NO_ALREADY_EXISTS'
      ) {
        setComponentToAddInOurList({
          component_sds: sdsId,
          cas_no: cas_no,
          force_add: forceAddSameCasNo,
          index: componentIndex,
        });
        setOpenConfirmAddComponentPopup(true);
      } else {
        renderSnackbar([t('popups:import_failed')]);
      }
    });
  };

  const handleChangeComponentValue = (
    fieldIndex: number,
    value: any,
    fieldName: string
  ) => {
    dispatch(
      change(
        `ingredientsForm`,
        `ingredients[${fieldIndex}].${fieldName}`,
        value
      )
    );
  };

  const getContentFromHTML = (value: any) => {
    if (!value) {
      return value;
    }
    return value.replace(/<[^>]+>/g, '');
  };

  const searchComponentNameInput = (fieldIndex: any, value: any) => {
    return (
      <Autocomplete
        inputValue={getContentFromHTML(value) || ''}
        id="searchComponentNameInput"
        options={searchResults}
        getOptionLabel={(component: any) => component.product_name || component}
        renderOption={(props, option: any) => (
          <Typography
            {...props}
            dangerouslySetInnerHTML={{
              __html: `${option.product_name} (${option.cas_no})`,
            }}
          />
        )}
        onChange={(e, value: any) => {
          handleChangeComponentValue(
            fieldIndex,
            getContentFromHTML(
              value?.product_name ? value.product_name : value
            ),
            'component_name'
          );
          handleChangeComponentValue(
            fieldIndex,
            value?.cas_no ? value.cas_no : value,
            'cas_no'
          );
        }}
        freeSolo
        renderInput={(params) => (
          <Field
            name={`ingredients[${fieldIndex}].component_name`}
            component={TextFieldInputV2}
            label={t('sds_authoring:component_name')}
            onChange={(e: any) => {
              handleChange(e, 'component_name');
            }}
            {...params}
          />
        )}
      />
    );
  };

  const searchCasNumberInput = (fieldIndex: any, value: any) => {
    return (
      <Autocomplete
        id="searchCasNumberInput"
        options={searchResults}
        inputValue={value || ''}
        getOptionLabel={(component: any) => component.cas_no}
        renderOption={(props, option: any) => (
          <Typography
            {...props}
            dangerouslySetInnerHTML={{
              __html: `${option.product_name} (${option.cas_no})`,
            }}
          />
        )}
        onChange={(e, value: any) => {
          handleChangeComponentValue(
            fieldIndex,
            value?.cas_no ? value.cas_no : value,
            'cas_no'
          );
          handleChangeComponentValue(
            fieldIndex,
            getContentFromHTML(
              value?.product_name ? value.product_name : value
            ),
            'component_name'
          );
        }}
        renderInput={(params: any) => (
          <Field
            name={`ingredients[${fieldIndex}].cas_no`}
            component={TextFieldInputV2}
            label={t('sds_authoring:cas_number')}
            onChange={(e: any) => {
              handleChange(e, 'cas_no');
            }}
            {...params}
          />
        )}
        freeSolo
      />
    );
  };
  const selectComponentsPopupProps = {
    languageCode: languageCode,
    onSubmit: (elements: any) => {
      elements.forEach((el: any) => {
        fields.push({
          hash: uuidv4(),
          component_sds: el.id,
          component_name: el.sds_pdf_product_name,
          cas_no: el.cas_no,
          reach_no: el.reach_no,
          ec_no: el.ec_no,
          additionalData: el.additional_data,
          public_view_url: el.public_view_url,
          allow_add_to_our_list: true,
        });
      });
      setOpenAddComponentPopup(false);
    },
    open: openAddComponentPopup,
    onClose: () => {
      dispatch(setSelectedComponentId(null));
      setOpenAddComponentPopup(false);
    },
  };

  const selectOurComponentsListProps = {
    onSubmit: (elements: any) => {
      elements.forEach((el: any) => {
        const hash = uuidv4();
        fields.push({
          hash: hash,
          component_sds: el.component_sds,
          component_name: el.component_name,
          cas_no: el.cas_no,
          reach_no: el.reach_no,
          ec_no: el.ec_no,
          additionalData: el.structured_data,
          public_view_url: el.pdf_view_url,
          hazard_abbreviation: el.classifications
            ?.map((el: any) =>
              el.abbreviation ? `${el.abbreviation}, ${el.code}` : ''
            )
            ?.join('\n'),
        });
        dispatch(setComponentStructuredData({ [hash]: el.structured_data }));
      });
      setOpenAddOurComponentPopup(false);
    },
    open: openAddOurComponentPopup,
    onClose: () => {
      dispatch(setSelectedComponentId(null));
      setOpenAddOurComponentPopup(false);
    },
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        my={2}
      >
        <Typography>{t('sds_authoring:components')}</Typography>
        {isSubstanceSDS ? null : (
          <Box className={classes.buttonWrapper}>
            <Tooltip describeChild title={t('tooltips:add_manually')}>
              <Button
                variant="contained"
                onClick={() =>
                  fields.insert(0, { hash: uuidv4(), manuallyCreated: true })
                }
              >
                {t('actions:add_manually')}
              </Button>
            </Tooltip>
            <Tooltip describeChild title={t('tooltips:search_component')}>
              <Button
                variant="contained"
                onClick={() => setOpenAddComponentPopup(true)}
              >
                {t('actions:search_component')}
              </Button>
            </Tooltip>
            <Tooltip describeChild title={t('tooltips:add_from_our_list')}>
              <Button
                variant="contained"
                onClick={() => setOpenAddOurComponentPopup(true)}
              >
                {t('actions:add_our_component')}
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
      {fields?.getAll()?.map((ingredient: any, index: any) => {
        return (
          <Paper
            key={index}
            elevation={4}
            className={classes.ingredientWrapper}
          >
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, md: 6 }}>
              {ingredient.manuallyCreated ? (
                <Grid item md={6} xs={12}>
                  {searchComponentNameInput(index, ingredient.component_name)}
                </Grid>
              ) : (
                <Grid item md={6} xs={12}>
                  <Field
                    fullWidth
                    name={`ingredients[${index}].component_name`}
                    component={TextFieldInputV2}
                    label={t('sds_authoring:component_name')}
                  />
                </Grid>
              )}
              {ingredient.manuallyCreated ? (
                <Grid item xs={6}>
                  {searchCasNumberInput(index, ingredient.cas_no)}
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    name={`ingredients[${index}].cas_no`}
                    component={TextFieldInputV2}
                    label={t('sds_authoring:cas_number')}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Field
                  name={`ingredients[${index}].ec_no`}
                  component={TextFieldInputV2}
                  fullWidth
                  label={t('sds_authoring:ec_number')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name={`ingredients[${index}].reach_no`}
                  component={TextFieldInputV2}
                  fullWidth
                  label={t('sds_authoring:reach_number')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name={`ingredients[${index}].hazard_abbreviation`}
                  component={TextFieldInputV2}
                  multiline={true}
                  minRows={2}
                  disabled={isSubstanceSDS}
                  fullWidth
                  label={t('sds_authoring:classifications')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name={`ingredients[${index}].concentration`}
                  component={TextFieldInputV2}
                  fullWidth
                  label={t('sds_authoring:concentration')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {structuredData[
                  ingredient.id ? ingredient.id : ingredient.hash
                ] && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !(
                              doNotIncludeStructuredDataFromComponents.includes(
                                ingredient.id
                              ) ||
                              doNotIncludeStructuredDataFromComponents.includes(
                                ingredient.hash
                              )
                            )
                          }
                          color="primary"
                          onClick={(e: any) => {
                            dispatch(setStructuredDataEdited(true));
                            const identifier = ingredient.id
                              ? ingredient.id
                              : ingredient.hash;
                            if (e.target.checked) {
                              dispatch(
                                setDoNotIncludeStructuredDataFromComponents(
                                  doNotIncludeStructuredDataFromComponents.map(
                                    (el: any) => el !== identifier
                                  )
                                )
                              );
                            } else {
                              dispatch(
                                setDoNotIncludeStructuredDataFromComponents([
                                  ...doNotIncludeStructuredDataFromComponents,
                                  identifier,
                                ])
                              );
                            }
                          }}
                        />
                      }
                      label={t('sds_authoring:include_structured_data_message')}
                    />
                  </div>
                )}
                <Tooltip
                  describeChild
                  title={t('tooltips:open_create_structured_data_popup')}
                >
                  <Button
                    onClick={() =>
                      handleOpenAddStructuredComponentData(
                        ingredient.id ? ingredient.id : ingredient.hash
                      )
                    }
                  >
                    {t('sds_authoring:open_create_structured_data_popup')}
                  </Button>
                </Tooltip>
                {ingredient.public_view_url && !ingredient.imported && (
                  <div>
                    <a
                      style={{ textDecoration: 'none' }}
                      href={ingredient.public_view_url}
                      target="_blank"
                    >
                      <Button>{t('sds_authoring:open_source_pdf')}</Button>
                    </a>
                  </div>
                )}
                {ingredient.allow_add_to_our_list && (
                  <Button
                    onClick={() =>
                      handleAddComponentToOurList(
                        ingredient.component_sds,
                        ingredient.cas_no,
                        false,
                        index
                      )
                    }
                  >
                    {t('sds_authoring:add_this_component_to_our_list')}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Button onClick={() => fields.remove(index)}>
              <Delete />
            </Button>
          </Paper>
        );
      })}
      {openCreateStructuredDataPopup && selectedComponentId && (
        <CreateComponentStructuredDataPopup
          open={openCreateStructuredDataPopup}
          componentId={selectedComponentId}
          onClose={() => {
            dispatch(setSelectedComponentId(null));
            setOpenCreateStructuredDataPopup(false);
          }}
        />
      )}
      {openAddComponentPopup && (
        <SelectComponentsPopup {...selectComponentsPopupProps} />
      )}
      {openAddOurComponentPopup && (
        <SelectComponentFromOurComponentsList
          {...selectOurComponentsListProps}
        />
      )}
      {openConfirmAddComponentPopup && componentToAddInOurList && (
        <ConfirmActionPopup
          open={openConfirmAddComponentPopup}
          title={t('popups:component_already_exists')}
          onClose={() => {
            setComponentToAddInOurList(null);
            setOpenConfirmAddComponentPopup(false);
          }}
          onSubmit={() =>
            handleAddComponentToOurList(
              componentToAddInOurList.component_sds,
              componentToAddInOurList.cas_no,
              true,
              componentToAddInOurList.index
            )
          }
        />
      )}
    </div>
  );
};

export default Ingredients;
