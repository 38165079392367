import { WORKPLACE_SAFETY_ORIGIN } from "../constants";

export const isMobile = (): boolean => {
  return window.matchMedia('(max-width: 767px)').matches;
};

export const getEnv = (): string => {
  const host = window.location.hostname;
  if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1)
    return 'development';

  if (host.indexOf('staging') > -1) return 'staging';

  if (host.indexOf('rc') > -1) return 'rc';

  return 'production';
};


export const isForWorkplaceSafety = () => {
  const currentOrigin = window.location.origin
  if (currentOrigin.includes(WORKPLACE_SAFETY_ORIGIN)) return true;
  return false;
}

export const multipleClasses = (arr: string[]) => {
  return arr.join(' ');
}